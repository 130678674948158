import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component:  () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component:  () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/decode',
    name: 'decode',
    component:  () => import(/* webpackChunkName: "about" */ '../views/Decode.vue')
  },
  {
    path: '/decodet',
    name: 'decodet',
    component:  () => import(/* webpackChunkName: "about" */ '../views/DecodeT.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
