import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Toast } from 'vant';

Vue.use(Toast);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
